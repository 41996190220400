<template>
  <v-footer class="justify-center">
    <!--<div><a target="_blank" href="https://taicca.tw">Taiwan Creative Content Agency (TAICCA)</a></div>-->
    <!--<social-media large />-->
    <div class="copyright text-center">
      Copyright © 2020 Throne Inc., NADA, TAICCA. All rights reserved.
    </div>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    components: {
      // SocialMedia: () => import('@/components/SocialMedia'),
    },
  }
</script>
